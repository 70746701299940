import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Shield, Cpu, Lock } from 'lucide-react';
import ValueCard from '../components/ValueCard';
import Header from '../components/Header';
import Footer from '../components/Footer';

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us - SOFTech Integrations LLC</title>
        <meta name="description" content="Learn about SOFTech Integrations LLC's mission, values, and expertise in secure technology solutions for federal and private sector clients." />
      </Helmet>

      <div className="min-h-screen flex flex-col">
        <Header />
        
        <main className="flex-grow pt-20">
          {/* Founder Section */}
          <section className="py-16 bg-navy-50 dark:bg-slate-800">
            <div className="container mx-auto px-4">
              <div className="max-w-3xl mx-auto">
                <h1 className="text-4xl font-bold text-navy-800 dark:text-white mb-8 text-center">About SOFTech Integrations</h1>
                <div className="bg-white dark:bg-slate-900 rounded-lg shadow-md p-8">
                  <h2 className="text-2xl font-bold text-navy-700 dark:text-white mb-4">Founder's Vision</h2>
                  <p className="text-gray-700 dark:text-gray-300 leading-relaxed mb-6">
                    Justin Nichols, founder of SOFTech Integrations LLC, brings a wealth of experience from the intelligence and defense sectors, backed by a strong foundation in data integration, full-stack development, and technical operations. Driven by a mission to empower clients through secure and innovative technology, SOFTech Integrations was founded on principles of reliability, adaptability, and technical excellence.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Core Values Section */}
          <section className="py-16 bg-white dark:bg-slate-900">
            <div className="container mx-auto px-4">
              <h2 className="text-3xl font-bold text-center text-navy-800 dark:text-white mb-12">Our Core Values</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
                <ValueCard
                  title="Integrity"
                  description="Every project is completed with honesty and a commitment to doing what's right, every step of the way."
                  Icon={Shield}
                />
                <ValueCard
                  title="Reliability"
                  description="We deliver on our promises, consistently providing solutions that exceed expectations and withstand the test of time."
                  Icon={Cpu}
                />
                <ValueCard
                  title="Innovation in Secure Solutions"
                  description="Bringing creative approaches to secure, high-stakes environments with an eye on both performance and protection."
                  Icon={Lock}
                />
              </div>
            </div>
          </section>

          {/* Focus Areas Section */}
          <section className="py-16 bg-gray-50 dark:bg-slate-800">
            <div className="container mx-auto px-4">
              <div className="max-w-3xl mx-auto">
                <h2 className="text-3xl font-bold text-navy-800 dark:text-white mb-8 text-center">Our Focus</h2>
                <div className="bg-white dark:bg-slate-900 rounded-lg shadow-md p-8">
                  <p className="text-gray-700 dark:text-gray-300 leading-relaxed mb-8">
                    SOFTech Integrations LLC is committed to delivering results across industries requiring robust, secure technology solutions. With a background in supporting sensitive operations, we excel in high-security environments but adapt our approach to meet diverse client needs. Our team values collaboration, communication, and thorough understanding—ensuring your project's unique demands are met with precision and dedication.
                  </p>
                  <div className="border-t border-gray-200 dark:border-gray-700 pt-8">
                    <h3 className="text-xl font-bold text-navy-700 dark:text-white mb-4">Certifications & Experience</h3>
                    <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                      With experience in the TS/SCI space, SOFTech Integrations has the expertise to handle security-oriented projects requiring specialized knowledge. Justin's technical skills are complemented by hands-on knowledge in secure data integration, full-stack architecture, and mission-critical support services, making SOFTech an ideal partner for clients seeking both dependability and innovation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Footer />
      </div>
    </>
  );
};

export default About;