import React from 'react';
import { Mail, Phone, MapPin, Clock } from 'lucide-react';

const ContactInfo = () => {
  return (
    <div className="bg-white dark:bg-slate-800 rounded-lg shadow-md p-8">
      <h2 className="text-2xl font-bold text-navy-800 dark:text-white mb-6">Contact Information</h2>
      
      <div className="space-y-6">
        <div className="flex items-start">
          <Mail className="h-6 w-6 text-navy-600 dark:text-navy-400 mt-1 mr-4" />
          <div>
            <h3 className="font-semibold text-gray-900 dark:text-white">Email</h3>
            <p className="text-gray-600 dark:text-gray-300">justin.nichols@softechintegrations.com</p>
          </div>
        </div>

        <div className="flex items-start">
          <Phone className="h-6 w-6 text-navy-600 dark:text-navy-400 mt-1 mr-4" />
          <div>
            <h3 className="font-semibold text-gray-900 dark:text-white">Phone</h3>
            <p className="text-gray-600 dark:text-gray-300">(910) 870-0000</p>
          </div>
        </div>

        <div className="flex items-start">
          <MapPin className="h-6 w-6 text-navy-600 dark:text-navy-400 mt-1 mr-4" />
          <div>
            <h3 className="font-semibold text-gray-900 dark:text-white">Location</h3>
            <p className="text-gray-600 dark:text-gray-300">Fayetteville, NC</p>
          </div>
        </div>

        <div className="flex items-start">
          <Clock className="h-6 w-6 text-navy-600 dark:text-navy-400 mt-1 mr-4" />
          <div>
            <h3 className="font-semibold text-gray-900 dark:text-white">Business Hours</h3>
            <p className="text-gray-600 dark:text-gray-300">Monday - Friday: 9:00 AM - 5:00 PM EST</p>
          </div>
        </div>
      </div>

      <div className="mt-8 pt-8 border-t border-gray-200 dark:border-slate-700">
        <h3 className="font-semibold text-gray-900 dark:text-white mb-4">Quick Links</h3>
        <div className="flex flex-wrap gap-4">
          <a href="/" className="text-navy-600 hover:text-navy-700 dark:text-navy-400 dark:hover:text-navy-300">Home</a>
          <span className="text-gray-300 dark:text-gray-600">|</span>
          <a href="/about" className="text-navy-600 hover:text-navy-700 dark:text-navy-400 dark:hover:text-navy-300">About Us</a>
          <span className="text-gray-300 dark:text-gray-600">|</span>
          <a href="/services" className="text-navy-600 hover:text-navy-700 dark:text-navy-400 dark:hover:text-navy-300">Our Services</a>
          <span className="text-gray-300 dark:text-gray-600">|</span>
          <a href="/contact" className="text-navy-600 hover:text-navy-700 dark:text-navy-400 dark:hover:text-navy-300">Contact Us</a>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;