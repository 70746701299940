import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactInfo from '../components/ContactInfo';

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us - SOFTech Integrations LLC</title>
        <meta name="description" content="Get in touch with SOFTech Integrations LLC in Fayetteville, NC. Let's discuss how we can help bring your technology vision to life with our secure and scalable solutions." />
      </Helmet>

      <div className="min-h-screen flex flex-col">
        <Header />
        
        <main className="flex-grow pt-20">
          {/* Hero Section */}
          <section className="py-16 bg-navy-50">
            <div className="container mx-auto px-4">
              <div className="max-w-3xl mx-auto text-center">
                <h1 className="text-4xl font-bold text-navy-800 mb-6">Get In Touch with SOFTech Integrations</h1>
                <p className="text-xl text-gray-700 leading-relaxed">
                  We'd love to learn more about your project and discuss how SOFTech Integrations LLC can bring your vision to life. Contact us using the information below, and our team will get back to you as soon as possible.
                </p>
              </div>
            </div>
          </section>

          {/* Contact Form and Info Section */}
          <section className="py-16 bg-white">
            <div className="container mx-auto px-4">
              <div className="max-w-6xl mx-auto">
                <ContactInfo />
              </div>
            </div>
          </section>

          {/* Map Section */}
          <section className="py-16 bg-gray-50">
            <div className="container mx-auto px-4">
              <div className="max-w-6xl mx-auto">
                <div className="bg-white rounded-lg shadow-md p-8">
                  <h2 className="text-2xl font-bold text-navy-800 mb-6">Our Location</h2>
                  <div className="aspect-w-16 aspect-h-9">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d103600.88269302587!2d-79.0558739873547!3d35.05292369477536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89ab6e7b5a0c83b7%3A0x4e436676b8f47892!2sFayetteville%2C%20NC!5e0!3m2!1sen!2sus!4v1710644160000!5m2!1sen!2sus"
                      className="w-full h-[400px] rounded-lg"
                      style={{ border: 0 }}
                      allowFullScreen
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Footer />
      </div>
    </>
  );
};

export default Contact;
