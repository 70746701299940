import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Code2, Database, FileSearch, ArrowRight } from 'lucide-react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DetailedServiceCard from '../components/DetailedServiceCard';

const Services = () => {
  return (
    <>
      <Helmet>
        <title>Services - SOFTech Integrations LLC</title>
        <meta name="description" content="Explore SOFTech Integrations LLC's comprehensive technical services including full-stack development, data integration, and technical requirements support." />
      </Helmet>

      <div className="min-h-screen flex flex-col">
        <Header />
        
        <main className="flex-grow pt-20">
          {/* Hero Section */}
          <section className="py-16 bg-navy-50 dark:bg-slate-800">
            <div className="container mx-auto px-4">
              <div className="max-w-3xl mx-auto text-center">
                <h1 className="text-4xl font-bold text-navy-800 dark:text-white mb-6">Our Services</h1>
                <p className="text-xl text-gray-700 dark:text-gray-300 leading-relaxed">
                  SOFTech Integrations offers a range of technical services tailored to meet high-performance and security standards. From full-stack development to advanced data integration, our work empowers clients with solutions that are both powerful and resilient.
                </p>
              </div>
            </div>
          </section>

          {/* Detailed Services Section */}
          <section className="py-16 section-light">
            <div className="container mx-auto px-4">
              <div className="space-y-12 max-w-4xl mx-auto">
                <DetailedServiceCard
                  title="Full-Stack Development Support"
                  description="We develop scalable, end-to-end applications using a modern tech stack. SOFTech provides full-stack solutions that are not only robust but also adaptable, designed to scale with your organization's evolving needs. Our approach integrates frontend and backend systems seamlessly, ensuring consistent performance and user engagement."
                  Icon={Code2}
                  features={[
                    "Modern tech stack implementation",
                    "Scalable architecture design",
                    "Secure application development",
                    "Performance optimization",
                    "User experience enhancement"
                  ]}
                />

                <DetailedServiceCard
                  title="Data Integration Support"
                  description="Data is central to strategic decision-making, and at SOFTech Integrations, we specialize in creating meaningful connections across diverse data sources. Using advanced ETL processes, data fusion techniques, and real-time processing, we help clients transform raw data into actionable insights while maintaining the highest security standards."
                  Icon={Database}
                  features={[
                    "Advanced ETL processes",
                    "Real-time data processing",
                    "Data fusion techniques",
                    "Security compliance",
                    "Analytics integration"
                  ]}
                />

                <DetailedServiceCard
                  title="Technical Requirements Support"
                  description="Our solutions are driven by a deep understanding of each client's unique needs. SOFTech excels in analyzing and delivering on complex technical requirements, whether for secure applications, data systems, or operational tools. We work closely with clients to develop solutions tailored to their specific objectives, ensuring technology aligns seamlessly with mission goals."
                  Icon={FileSearch}
                  features={[
                    "Requirements analysis",
                    "Solution architecture",
                    "Technical documentation",
                    "Implementation planning",
                    "Operational alignment"
                  ]}
                />
              </div>
            </div>
          </section>

          {/* CTA Section */}
          <section className="py-16 bg-gradient-to-br from-navy-800 to-navy-900 dark:from-slate-800 dark:to-slate-900">
            <div className="container mx-auto px-4">
              <div className="max-w-3xl mx-auto text-center">
                <h2 className="text-3xl font-bold text-white mb-6">Ready to Get Started?</h2>
                <p className="text-xl mb-8 text-navy-50">
                  Ready to discuss a tailored solution for your organization? Reach out to our team to explore how SOFTech Integrations LLC can support your goals.
                </p>
                <a
                  href="/contact"
                  className="inline-flex items-center bg-white dark:bg-navy-600 text-navy-800 dark:text-white px-8 py-3 rounded-md font-semibold hover:bg-navy-50 dark:hover:bg-navy-700 transition-colors shadow-lg hover:shadow-xl"
                >
                  Contact Us
                  <ArrowRight className="ml-2 h-5 w-5" />
                </a>
              </div>
            </div>
          </section>
        </main>

        <Footer />
      </div>
    </>
  );
};

export default Services;