import React from 'react';
import { LucideIcon } from 'lucide-react';

interface ValueCardProps {
  title: string;
  description: string;
  Icon: LucideIcon;
}

const ValueCard: React.FC<ValueCardProps> = ({ title, description, Icon }) => {
  return (
    <div className="bg-white dark:bg-slate-800 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow">
      <div className="flex items-center mb-4">
        <div className="bg-navy-50 dark:bg-navy-900 rounded-full p-3 mr-4">
          <Icon className="h-6 w-6 text-navy-600 dark:text-navy-400" />
        </div>
        <h3 className="text-xl font-bold text-gray-800 dark:text-white">{title}</h3>
      </div>
      <p className="text-gray-600 dark:text-gray-300">{description}</p>
    </div>
  );
};

export default ValueCard;