import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Code2, Database, Users, ArrowRight } from 'lucide-react';
import Header from './components/Header';
import Footer from './components/Footer';
import ServiceCard from './components/ServiceCard';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <div className="min-h-screen flex flex-col">
            <Helmet>
              <title>SOFTech Integrations LLC - Secure & Scalable Technology Solutions</title>
              <meta name="description" content="SOFTech Integrations LLC specializes in secure and scalable technology solutions for federal and private sector clients." />
            </Helmet>
            <Header />
            
            {/* Hero Section */}
            <main className="flex-grow">
              <section className="pt-24 pb-12 bg-hero-pattern text-white relative overflow-hidden">
                <div className="absolute inset-0 bg-gradient-radial from-navy-500/20 to-navy-900/40 dark:from-slate-800/90 dark:to-slate-900"></div>
                <div className="container mx-auto px-4 relative">
                  <div className="max-w-4xl mx-auto text-center">
                    <h1 className="text-4xl md:text-5xl font-bold mb-6 text-white">
                      Empowering Secure and Scalable Tech Solutions
                    </h1>
                    <p className="text-xl md:text-2xl mb-8 text-navy-50">
                      Specializing in Full-Stack Development, Data Integration, and Technical Support for Federal and Private Sector Clients
                    </p>
                    <a
                      href="/services"
                      className="btn-secondary"
                    >
                      Explore Our Services
                    </a>
                  </div>
                </div>
              </section>

              {/* Services Section */}
              <section className="py-16 bg-white dark:bg-slate-900">
                <div className="container mx-auto px-4">
                  <div className="text-center mb-12">
                    <h2 className="text-3xl font-bold text-navy-800 dark:text-white mb-4">
                      Our Core Services
                    </h2>
                    <p className="text-xl text-gray-600 dark:text-gray-300">
                      Delivering excellence through our comprehensive suite of technology solutions
                    </p>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <ServiceCard
                      title="Full-Stack Development"
                      description="Secure and scalable applications built with modern technologies and best practices."
                      Icon={Code2}
                    />
                    <ServiceCard
                      title="Data Integration"
                      description="Seamless integration of complex data systems and APIs for efficient operations."
                      Icon={Database}
                    />
                    <ServiceCard
                      title="Technical Support"
                      description="Expert support and maintenance for mission-critical systems and applications."
                      Icon={Users}
                    />
                  </div>
                </div>
              </section>

              {/* CTA Section */}
              <section className="py-16 bg-gradient-to-br from-navy-800 to-navy-900 dark:from-slate-800 dark:to-slate-900">
                <div className="container mx-auto px-4">
                  <div className="max-w-3xl mx-auto text-center">
                    <h2 className="text-3xl font-bold text-white mb-6">Ready to Get Started?</h2>
                    <p className="text-xl mb-8 text-navy-50">
                      Let's discuss how SOFTech Integrations can help bring your technology vision to life.
                    </p>
                    <a
                      href="/contact"
                      className="inline-flex items-center bg-white dark:bg-navy-600 text-navy-800 dark:text-white px-8 py-3 rounded-md font-semibold hover:bg-navy-50 dark:hover:bg-navy-700 transition-colors shadow-lg hover:shadow-xl"
                    >
                      Contact Us
                      <ArrowRight className="ml-2 h-5 w-5" />
                    </a>
                  </div>
                </div>
              </section>
            </main>
            <Footer />
          </div>
        } />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
