import React from 'react';
import { LucideIcon } from 'lucide-react';

interface ServiceCardProps {
  title: string;
  description: string;
  Icon: LucideIcon;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ title, description, Icon }) => {
  return (
    <div className="group service-card">
      <div className="service-card-icon">
        <Icon className="h-8 w-8 text-navy-600 dark:text-navy-400 
                        group-hover:text-navy-700 dark:group-hover:text-navy-300 
                        transition-colors duration-300" />
      </div>
      <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-3 
                     text-center group-hover:text-navy-600 
                     dark:group-hover:text-navy-400 transition-colors duration-300">
        {title}
      </h3>
      <p className="text-gray-600 dark:text-gray-300 text-center 
                    group-hover:text-gray-700 dark:group-hover:text-gray-200 
                    transition-colors duration-300">
        {description}
      </p>
    </div>
  );
};

export default ServiceCard;
