import React from 'react';
import { LucideIcon, Check } from 'lucide-react';

interface DetailedServiceCardProps {
  title: string;
  description: string;
  Icon: LucideIcon;
  features: string[];
}

const DetailedServiceCard: React.FC<DetailedServiceCardProps> = ({
  title,
  description,
  Icon,
  features,
}) => {
  return (
    <div className="bg-white dark:bg-slate-800 rounded-lg shadow-lg p-8 border border-gray-100 dark:border-slate-700">
      <div className="flex items-start">
        <div className="bg-navy-50 dark:bg-navy-900 rounded-lg p-4 mr-6">
          <Icon className="h-8 w-8 text-navy-600 dark:text-navy-400" />
        </div>
        <div>
          <h3 className="text-2xl font-bold text-navy-800 dark:text-white mb-4">{title}</h3>
          <p className="text-gray-600 dark:text-gray-300 mb-6 leading-relaxed">{description}</p>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-3">
            {features.map((feature, index) => (
              <li key={index} className="flex items-center text-gray-700 dark:text-gray-300">
                <Check className="h-5 w-5 text-navy-600 dark:text-navy-400 mr-2 flex-shrink-0" />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DetailedServiceCard;