import React from 'react';
import { Mail, Phone, MapPin } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-slate-900 text-white pt-12 pb-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          <div>
            <h3 className="text-xl font-bold mb-4 text-white">SOFTech Integrations</h3>
            <p className="text-slate-400 mb-4">
              Empowering organizations with secure and scalable technology solutions.
            </p>
            <div className="flex space-x-4">
              <a href="/contact" className="text-slate-400 hover:text-navy-300 transition-colors">
                <Mail className="h-5 w-5" />
              </a>
              <a href="/contact" className="text-slate-400 hover:text-navy-300 transition-colors">
                <Phone className="h-5 w-5" />
              </a>
              <a href="/contact" className="text-slate-400 hover:text-navy-300 transition-colors">
                <MapPin className="h-5 w-5" />
              </a>
            </div>
          </div>
          
          <div>
            <h3 className="text-xl font-bold mb-4 text-white">Quick Links</h3>
            <ul className="space-y-2">
              <li><a href="/" className="text-slate-400 hover:text-white transition-colors">Home</a></li>
              <li><a href="/about" className="text-slate-400 hover:text-white transition-colors">About</a></li>
              <li><a href="/services" className="text-slate-400 hover:text-white transition-colors">Services</a></li>
              <li><a href="/contact" className="text-slate-400 hover:text-white transition-colors">Contact</a></li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-xl font-bold mb-4 text-white">Contact Us</h3>
            <div className="space-y-2 text-slate-400">
              <p>Email: justin.nichols@softechintegrations.com</p>
              <p>Phone: (910) 870-0000</p>
              <p>Location: Fayetteville, NC</p>
            </div>
          </div>
        </div>
        
        <div className="border-t border-slate-800 pt-8 text-center text-slate-400">
          <p>&copy; {new Date().getFullYear()} SOFTech Integrations LLC. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;