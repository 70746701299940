import React from 'react';
import { Menu } from 'lucide-react';
import ThemeToggle from './ThemeToggle';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <header className="fixed top-0 w-full bg-white/95 dark:bg-slate-900/95 backdrop-blur-sm shadow-md z-50 transition-colors">
      <nav className="container mx-auto px-4 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <span className="text-2xl font-bold gradient-text dark:text-white">SOFTech</span>
            <span className="text-slate-600 dark:text-slate-300">Integrations</span>
          </div>
          
          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <a href="/" className="text-slate-600 hover:text-navy-600 dark:text-slate-300 dark:hover:text-navy-400 font-medium transition-colors">Home</a>
            <a href="/about" className="text-slate-600 hover:text-navy-600 dark:text-slate-300 dark:hover:text-navy-400 font-medium transition-colors">About</a>
            <a href="/services" className="text-slate-600 hover:text-navy-600 dark:text-slate-300 dark:hover:text-navy-400 font-medium transition-colors">Services</a>
            <ThemeToggle />
            <a href="/contact" className="btn-primary dark:bg-navy-500 dark:hover:bg-navy-600">
              Contact Us
            </a>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden flex items-center space-x-4">
            <ThemeToggle />
            <button 
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-label="Toggle menu"
            >
              <Menu className="h-6 w-6 text-slate-700 dark:text-slate-300" />
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden mt-4 pb-4">
            <div className="flex flex-col space-y-4">
              <a href="/" className="text-slate-600 hover:text-navy-600 dark:text-slate-300 dark:hover:text-navy-400 font-medium transition-colors">Home</a>
              <a href="/about" className="text-slate-600 hover:text-navy-600 dark:text-slate-300 dark:hover:text-navy-400 font-medium transition-colors">About</a>
              <a href="/services" className="text-slate-600 hover:text-navy-600 dark:text-slate-300 dark:hover:text-navy-400 font-medium transition-colors">Services</a>
              <a href="/contact" className="btn-primary dark:bg-navy-500 dark:hover:bg-navy-600 text-center">
                Contact Us
              </a>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;